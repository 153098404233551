<template>
  <div id="app" :class="theme">
    <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
    <!-- <Splash msg="made by ozzie" /> -->

    <b-navbar type="dark" sticky id="navbar">
      <b-navbar-brand href="#" v-on:click='showSplashPage'>Flyculator</b-navbar-brand>

      <b-navbar-nav class="ml-auto" pills>
        <b-nav-item href="#" :disabled="this.activePage == 'page_splash' && !sessionInitated" :active="this.activePage == 'page_flyers'" v-on:click='showFlyersPage'>Flyers</b-nav-item>
        <b-nav-item href="#" :disabled="this.activePage == 'page_splash' && !sessionInitated" :active="this.activePage == 'page_entries'" v-on:click='showEntriesPage'>Entries</b-nav-item>
        <b-nav-item href="#" :disabled="this.activePage == 'page_splash' && !sessionInitated" :active="this.activePage == 'page_settings'" v-on:click='showSettingsPage'>Settings</b-nav-item>
      </b-navbar-nav>
    </b-navbar>

    <!-- ### PAGE SPlASH ### -->
    <div ref="page_splash" v-show="this.activePage == 'page_splash'">
      <b-row class="mx-0">
        <b-col cols="12" md="8" offset-md="2" lg="6" offset-lg="3" class="px-0">
          <b-container fluid class="splash_bg">
            <b-form @submit.prevent="loadSession">
              <b-row class="py-5">
                <b-col cols="12" sm="8" offset-sm="2">
                  <b-img v-if="theme == 'ABS'" alt="Logo" fluid src="logo_abs.png" class="my-3 mt-sm-5 mb-sm-0"></b-img>
                  <b-img v-if="theme == 'HF'" alt="Logo" fluid src="logo_hf.png" class="my-4 mb-sm-0 pt-4"></b-img>
                  <!-- <b-img v-if="theme == 'WOB'" alt="Logo" fluid src="logo_wob.png" class="my-5 mb-sm-0 pt-4"></b-img> -->
                </b-col>
              </b-row>
              <b-row class="mt-sm-4 mt-md-5">
                <b-col cols="6" sm="4" offset-sm="2"><b-button block variant="primary" class="primary" v-on:click="createSession">New Session</b-button></b-col>
                <b-col cols="6" sm="4"><b-button block variant="primary" class="primary" v-on:click="showSessionLoader">Load Session</b-button></b-col>
              </b-row>
              <b-row v-if="this.splashForm_isVisible" class="py-3 mt-4 mx-2 mx-sm-5 splash_session_bg">
                <b-col cols="8" sm="6" offset-sm="1">
                  <b-form-input v-model="session_name" ref="_txtSplashSessionName" placeholder="Session name" maxlength="20"></b-form-input>
                </b-col>
                <b-col cols="4" sm="4"><b-button type="submit" block variant="primary" class="primary">Load</b-button></b-col>
              </b-row>
              <b-row class="mt-sm-4 mt-md-5">
                <b-col class="py-5 py-sm-4">[ check out our <a :href="website_link" target="_blank">website</a> ]</b-col>
              </b-row>
            </b-form>
          </b-container>
        </b-col>
      </b-row>
    </div>

    <!-- ### PAGE FLYERS ### -->
    <div ref="page_flyers" v-show="this.activePage == 'page_flyers'">
      <b-row class="mx-0">
        <b-col cols="12" md="8" offset-md="2" lg="6" offset-lg="3" class="px-0">
          <b-container fluid class="px-0">

            <table v-show="roster.length == 0" style="width: 100%" class="flyer_table">
              <tr>
                <th class="text-center py-4 flyer_empty">-- No flyers yet --</th>
              </tr>
            </table>

            <table v-show="roster.length > 0" style="width: 100%;" class="flyer_table">

              <tr class="table_header">
                <td class="text-left pl-3 pl-sm-5 py-3">Flyer</td>
                <td>#</td>
                <td class="text-right">Time</td>
                <td class="text-right">Costs</td>
                <td class="text-right pr-3 pr-sm-5" v-show="!flyersControls_isVisible">Cost/min</td>
                <td v-show="flyersControls_isVisible"></td>
              </tr>

              <tr v-for="r in roster" :key="r._id">
                <td class="text-left pl-3 pl-sm-5 py-3">
                  <div v-if="r.isCoach">
                    <div v-if="r.hasPaid">
                      <strike><b>{{ r.name }}</b></strike>
                    </div>
                    <div v-else>
                      <b>{{ r.name }}</b>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="r.hasPaid">
                      <strike>{{ r.name }}</strike>
                    </div>
                    <div v-else>
                      {{ r.name }}
                    </div>
                  </div>
                </td>
                <td>{{ getFlyerTotalsByID(r._id).entries }}</td>
                <td class="text-right">{{ formatMinutes(getFlyerTotalsByID(r._id).time) }}</td>
                <td class="text-right">{{ formatCurrency(getFlyerTotalsByID(r._id).costs) }}</td>
                <td class="text-right pr-3 pr-sm-5" v-show="!flyersControls_isVisible">{{ formatCurrency(getFlyerTotalsByID(r._id).costPerMin) }}</td>
                <td class="text-right" v-show="flyersControls_isVisible">
                  <b-button size="sm" variant="secondary" class="secondary" v-show="r.hasPaid" v-on:click="setFlyerPaid(r._id)"><b-icon-check-square-fill></b-icon-check-square-fill></b-button>
                  <b-button size="sm" variant="secondary" class="secondary" v-show="!r.hasPaid" v-on:click="setFlyerPaid(r._id)"><b-icon-check-square></b-icon-check-square></b-button>
                  <b-button size="sm" variant="primary" class="primary ml-1 mr-2 mr-sm-3" v-on:click="prepareUpdateFlyer(r._id)"><b-icon-pencil-square></b-icon-pencil-square></b-button>
                </td>
              </tr>

              <tr class="table_footer">
                <td class="text-left pl-3 pl-sm-5 py-3">{{ 'TOTAL [' + roster.length + ']' }}</td>
                <td>{{ getTotals().entries }}</td>
                <td class="text-right">{{ formatMinutes(getTotals().time) }}</td>
                <td class="text-right">{{ formatCurrency(getTotals().costs) }}</td>
                <td class="text-right pr-3 pr-sm-5" v-show="!flyersControls_isVisible">{{ formatCurrency(getTotals().costPerMin) }}</td>
                <td v-show="flyersControls_isVisible"></td>
              </tr>
            </table>
          </b-container>

          <b-container v-show="flyersForm_isVisible" fluid class="flyer_form">
            <b-form @submit.prevent="flyerSubmit">
              <b-row id="flyer_controls_new" class="pt-3 py-sm-3 flyer_form_content">
                <b-col cols="8" sm="4" class="pb-3 pb-sm-0">
                  <b-form-input placeholder="Flyer name" v-model="txtFlyerName" id="_txtFlyerName" ref="_txtFlyerName" maxlength="15"></b-form-input>
                </b-col>
                <b-col cols="4" sm="2" align-self="center" class="pb-3 pb-sm-0">
                  <b-form-checkbox v-model="chkFlyerIsCoach">Coach</b-form-checkbox>
                </b-col>
              </b-row>
              <b-row class="py-3">
                <b-col cols="6" sm="4" v-if="!isFlyerUpdate"><b-button type="submit" block variant="primary" id="_btnFlyerFormAdd" ref="_btnFlyerFormAdd" class="primary">Add Flyer</b-button></b-col>
                <b-col cols="6" sm="4" v-if="isFlyerUpdate"><b-button type="submit" block variant="primary" id="_btnFlyerFormUpd" ref="_btnFlyerFormUpd" class="primary">Update</b-button></b-col>
                <b-col cols="6" sm="4"><b-button block variant="secondary" class="secondary" v-on:click="flyersForm_isVisible = !flyersForm_isVisible">Cancel</b-button></b-col>
                <b-col cols="6" sm="4" v-show="isFlyerUpdate" class="pt-3 pt-sm-0" offset="3" offset-sm="0"><b-button block variant="danger" class="danger" v-on:click="deleteFlyer">Delete</b-button></b-col>
              </b-row>
            </b-form>
          </b-container>

          <b-container v-show="!flyersForm_isVisible && !hideControls" fluid class="flyer_form">
            <b-row class="py-3">
              <b-col cols="6" sm="4"><b-button block variant="primary" class="primary" v-on:click="prepareAddFlyer">New Flyer</b-button></b-col>
              <b-col cols="6" sm="4"><b-button block variant="secondary" class="secondary" style="min-width: 130px" v-show='roster.length > 0' v-on:click="flyersControls_isVisible = !flyersControls_isVisible">{{ flyersControls_isVisible ? 'Hide Controls' : 'Show Controls'  }}</b-button></b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </div>

    <!-- ### PAGE ENTRIES ### -->
    <div ref="page_entries" v-show="this.activePage == 'page_entries'">
      <b-row class="mx-0">
        <b-col cols="12" md="8" offset-md="2" lg="6" offset-lg="3" class="px-0">
          <b-container v-show="entries.length === 0" fluid class="entry_empty">
            <table style="width: 100%;">
              <tr>
                <th class="py-4">-- No entries yet --<div v-if="roster.length === 0"><small>You need to add flyers first.</small></div></th>
              </tr>
            </table>
          </b-container>
          <b-container v-show="entries.length > 0" fluid class="py-sm-2 pt-3 pb-2 entry_list">
            <div v-for="(e, index) in entries" :key="e._id" style="border-radius: 5px;" class="px-2 pt-2 px-sm-3 pt-sm-3 mb-2 mx-sm-3 mb-3 my-sm-3" :class="{ entry_marked: e.isMarked, entry_unmarked: !e.isMarked }" v-on:click="markEntry(index)">
              <b-row class="pt-1 pt-sm-0 pb-2">
                <b-col cols="2" class="text-left">#{{ index+1 }}</b-col>
                <b-col cols="4" sm="3" class="text-left">{{ formatMinutes(e.time) }} min</b-col>
                <b-col class="text-left">{{ e.note }}</b-col>
              </b-row>
              <b-row>
                <b-col class="mx-3 mx-sm-2 divider"></b-col>
              </b-row>
              <b-row class="pb-2 pb-sm-3 pt-1">
                <b-col class="text-left" v-show="false" v-html="getFlyerNamesByID(e.flyers)"></b-col>
                <b-col class="text-left">
                  <b-button v-for="(f, index) in e.flyers" :key="index" size="sm" class="mr-2 mt-2" :variant="selectedFlyer < 0 ? 'dark' : selectedFlyer === f ? 'dark' : 'outline-dark'" v-on:click.stop="selectFlyer(f)" v-html="getFlyerNameByID(f)"></b-button>
                </b-col>
              </b-row>
              <b-row v-show="entriesControls_isVisible" class="pt-1 pb-2 pb-sm-3">
                <b-col class="text-left" cols="7">
                  <b-button size="sm" class="mr-1 secondary" variant="secondary" :disabled="index == 0" v-on:click.stop="moveToTop(index)"><b-icon-arrow-bar-up></b-icon-arrow-bar-up></b-button>
                  <b-button size="sm" class="mr-1 secondary" variant="secondary" :disabled="index == 0" v-on:click.stop="moveUp(index)"><b-icon-arrow-up-short></b-icon-arrow-up-short></b-button>
                  <b-button size="sm" class="mr-1 secondary" variant="secondary" :disabled="index == entries.length - 1" v-on:click.stop="moveDown(index)"><b-icon-arrow-down-short></b-icon-arrow-down-short></b-button>
                  <b-button size="sm" class="mr-1 secondary" variant="secondary" :disabled="index == entries.length - 1" v-on:click.stop="moveToBottom(index)"><b-icon-arrow-bar-down></b-icon-arrow-bar-down></b-button>
                </b-col>
                <b-col class="text-right">
                  <b-button size="sm" class="mr-1 ml-3 secondary" variant="secondary" v-on:click.stop="duplicateEntry(index)"><b-icon-subtract></b-icon-subtract></b-button>
                  <b-button size="sm" class="mr-0 primary" variant="primary" v-on:click.stop="prepareUpdateEntry(index)"><b-icon-pencil-square></b-icon-pencil-square></b-button>
                </b-col>
              </b-row>
            </div>
          </b-container>

          <b-container v-show="entriesForm_isVisible" fluid class="py-4 pb-1 entry_form_content">
            <b-row class="pb-3">
              <b-col cols="2" class="text-center" align-self="center"><div style="border: solid 1px white; border-radius: 3px; font-size: smaller; background-color: #fefefe" class="p-1">{{ lblNumber }}</div></b-col>
              <b-col cols="4" sm="3" class="text-left">
                <b-form-select size="sm" v-model="cmbEntryTime" :options="entry_times"></b-form-select>
              </b-col>
              <b-col cols="6" sm="5" class="text-left">
                <b-form-input size="sm" v-model="txtNote" id="_txtNote" maxlength="20" placeholder="Note"></b-form-input>
              </b-col>
            </b-row>
            <b-row v-show="flyersAdded.length > 0">
              <b-col class="text-left">
                <b-button v-for="(f, index) in flyersAdded" :key="f._id" class="mr-2 my-1" variant="light" v-on:click="removeFlyerFromEntry(index)"><span v-html="f.isCoach ? '<b>' + f.name + '</b>' : f.name"></span> <b-badge variant="dark">{{ f.entries }}</b-badge></b-button>
              </b-col>
            </b-row>
            <b-row v-show="flyersAdded.length === 0">
              <b-col class="text-left">
                <b-button disabled variant="outline-dark" class="my-1">No flyers selected</b-button>
              </b-col>
            </b-row>
            <div class="px-2 px-sm-3 py-2 py-sm-3 mt-4 mb-2 mx-sm-3 mx-3 box">
              <b-row class="pt-2 pt-sm-1">
                <b-col>Select flyers who fly in this entry:</b-col>
              </b-row>
              <b-row>
                <b-col><hr></b-col>
              </b-row>
              <b-row v-show="flyersToAdd.length > 0">
                <b-col class="text-left">
                  <b-button v-for="(f, index) in flyersToAdd" :key="f._id" class="m-1" variant="secondary" v-on:click="addFlyerToEntry(index)"><span v-html="f.isCoach ? '<b>' + f.name + '</b>' : f.name"></span> <b-badge variant="light">{{ f.entries }}</b-badge></b-button>
                </b-col>
              </b-row>
              <b-row v-show="flyersToAdd.length === 0">
                <b-col>
                  <b-button disabled variant="outline-dark" class="my-1">All In</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <hr>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" sm="4" offset-sm="2" class="text-left">
                  <b-button block variant="dark" :disabled="flyersToAdd.length === 0" v-on:click="addAllFlyersToEntry">Select All</b-button>
                </b-col>
                <b-col cols="6" sm="4">
                  <b-button block variant="dark" :disabled="flyersAdded.length === 0" v-on:click="removeAllFlyersFromEntry">Select None</b-button>
                </b-col>
              </b-row>
            </div>
          </b-container>
          <b-container v-show="entriesForm_isVisible" fluid class="py-3 pb-sm-0 entry_form">
            <b-row class="pb-sm-3 pb-0">
              <b-col cols="6" sm="4" v-show="!isEntryUpdate"><b-button block variant="primary" class="primary" v-on:click="addEntry" id="_btnEntryFormAdd" ref="_btnEntryFormAdd">Add Entry</b-button></b-col>
              <b-col cols="6" sm="4" v-show="isEntryUpdate"><b-button block variant="primary" class="primary" v-on:click="updateEntry" id="_btnEntryFormUpd" ref="_btnEntryFormUpd">Update</b-button></b-col>
              <b-col cols="6" sm="4"><b-button block variant="secondary" class="secondary" v-on:click="entriesForm_isVisible = !entriesForm_isVisible">Cancel</b-button></b-col>
              <b-col cols="6" sm="4" v-show="isEntryUpdate" offset="3" offset-sm="0" class="pt-3 pt-sm-0"><b-button block variant="danger" class="danger" v-on:click="deleteEntry">Delete</b-button></b-col>
            </b-row>
          </b-container>
          <b-container v-show="!entriesForm_isVisible && !hideControls && roster.length > 0" fluid class="py-0 pb-sm-0 entry_form">
            <b-row class="py-3">
              <b-col cols="6" sm="4"><b-button block variant="primary" class="primary" v-on:click="prepareAddEntry">New Entry</b-button></b-col>
              <b-col cols="6" sm="4"><b-button block variant="secondary" class="secondary" style="min-width: 130px" v-show='entries.length > 0' v-on:click="entriesControls_isVisible = !entriesControls_isVisible">{{ entriesControls_isVisible ? 'Hide Controls' : 'Show Controls'  }}</b-button></b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </div>

    <!-- ### PAGE SETTINGS ### -->
    <div ref="page_settings" v-show="this.activePage == 'page_settings'">
      <b-row class="mx-0">
        <b-col cols="12" md="8" offset-md="2" lg="6" offset-lg="3" class="px-0">
          <b-container fluid class="px-4 px-sm-5 py-4 pb-1 settings_bg">
            <b-row class=" pb-2">
              <b-col cols="5" class="text-left" align-self="center">Theme</b-col>
              <b-col cols="7" class="text-left"><b-form-select size="sm" v-model="theme" :options="themes" v-on:change="onThemeChange" disabled></b-form-select></b-col>
            </b-row>
            <b-row class="pb-3">
              <b-col cols="5" class="text-left" align-self="center">Price per hour</b-col>
              <b-col cols="4" class="text-left">
                <b-form-input size="sm" placeholder="Cost" v-model="cost" maxlength="10" type="number" v-on:change="writeToLocalStorage"></b-form-input>
              </b-col>
              <b-col cols="3" class="text-left">
                <b-form-select size="sm" v-model="currency" :options="currencies" v-on:change="writeToLocalStorage"></b-form-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="5" class="text-left">View Mode</b-col>
              <b-col cols="7" class="text-left"><b-form-checkbox v-model="hideControls" v-on:change="writeToLocalStorage">No controls</b-form-checkbox></b-col>
            </b-row>
            <b-row v-show="user_logged_in && user_verified || session_name_loaded !== ''">
              <b-col><hr></b-col>
            </b-row>
            <b-row class="pb-2" v-show="user_logged_in && user_verified || session_name_loaded !== ''">
              <b-col class="text-left"><b>Session details</b></b-col>
            </b-row>
            <b-row class="pb-2" v-show="user_logged_in && user_verified || session_name_loaded !== ''">
              <b-col cols="5" class="text-left" align-self="center">Session</b-col>
              <b-col cols="7" class="text-left"><b-form-input size="sm" v-model="session_name" maxlength="40" name="txtSession" id="txtSession" placeholder="Session Name" :disabled="!user_logged_in || !user_verified"></b-form-input></b-col>
            </b-row>
            <b-row class="pb-1" v-show="user_logged_in && user_verified">
              <b-col cols="5" class="text-left" align-self="center">Password</b-col>
              <b-col cols="7" class="text-left"><b-form-input size="sm" v-model="session_password" maxlength="40" type="password" placeholder="Session Password" :disabled="!user_logged_in"></b-form-input></b-col>
            </b-row>
            <b-row class="pb-3 pt-2" v-show="session_name_loaded !== '' && session_name === session_name_loaded">
              <b-col cols="5" class="text-left">Last updated:</b-col>
              <b-col cols="7" class="text-left">{{ formatTime(last_updated) }}</b-col>
            </b-row>
            <b-row class="pb-0" v-show="session_name_loaded !== '' && session_name === session_name_loaded">
              <b-col cols="5" class="text-left">Updated by:</b-col>
              <b-col cols="7" class="text-left">{{ last_updated_by_user }}</b-col>
            </b-row>
            <b-row class="pt-4 pb-2" v-show="user_logged_in && user_verified" cols="2">
              <b-col cols="6" sm="4" v-show="session_name !== session_name_loaded || session_name === ''"><b-button block variant="primary" class="primary" size="sm" v-on:click="save">Save</b-button></b-col>
              <b-col cols="6" sm="4" v-show="session_name !== session_name_loaded || session_name === ''"><b-button block variant="secondary" class="secondary" size="sm" v-on:click="generateName">Generate Name</b-button></b-col>
              <b-col cols="6" sm="4" v-show="session_name === session_name_loaded && session_name !== ''"><b-button block variant="primary" class="primary" size="sm" v-on:click="update">Update</b-button></b-col>
              <b-col cols="6" sm="4" v-show="session_name === session_name_loaded && session_name !== ''"><b-button block variant="secondary" size="sm" class="clipBtn secondary" :data-clipboard-text="link_to_copy" v-on:click="copyLink" >Copy Link</b-button></b-col>
              <b-col cols="6" sm="4" offset="6" offset-sm="0" v-show="session_name === session_name_loaded && session_name !== ''" class="pt-3 pt-sm-0"><b-button block variant="secondary" size="sm" class="secondary" v-on:click="generateQRCode" >QR Code</b-button></b-col>
            </b-row>
            <b-row>
              <b-col><hr></b-col>
            </b-row>
            <b-row class="pb-2">
              <b-col class="text-left"><b>Online Session Sharing</b></b-col>
            </b-row>
            <b-row class="pb-3" v-show="user_logged_in">
              <b-col cols="5" class="text-left" align-self="start">Logged in as:</b-col>
              <b-col cols="7" class="text-left">{{ user_name }}</b-col>
            </b-row>
            <b-row class="pb-2" v-show="!user_verified && user_logged_in">
              <b-col cols="5" class="text-left" align-self="center">User Verification</b-col>
              <b-col cols="7" class="text-left"><b-button block variant="outline-secondary" class="outline-secondary" size="sm" v-on:click='resendVerification'>Re-send Verification-Link</b-button></b-col>
            </b-row>
            <b-row class="pb-2" v-show="!user_logged_in">
              <b-col cols="5" class="text-left" align-self="center">Your Email</b-col>
              <b-col cols="7" class="text-left"><b-form-input size="sm" v-model="user_email" ref="txtEmail" maxlength="40" placeholder=""></b-form-input></b-col>
            </b-row>
            <b-row class="pb-2" v-show="!user_logged_in">
              <b-col cols="5" class="text-left" align-self="center">Your Password</b-col>
              <b-col cols="7" class="text-left"><b-form-input size="sm" v-model="user_password" ref="txtPassword" maxlength="40" type="password" placeholder="(min. 6 characters)"></b-form-input></b-col>
            </b-row>
            <b-row class="pb-3" v-show="!user_logged_in">
              <b-col cols="5" class="text-left" align-self="center">Forgot it?</b-col>
              <b-col cols="7" class="text-left"><b-button block variant="outline-secondary" class="outline-secondary" size="sm" v-on:click='resetPassword'>Resend Password</b-button></b-col>
            </b-row>
            <b-row class="pt-2 pb-2">
              <b-col cols="6" sm="4" v-show="!user_logged_in"><b-button block variant="primary" class="primary" size="sm" v-on:click='login'>Login</b-button></b-col>
              <b-col cols="6" sm="4" v-show="user_logged_in"><b-button block variant="secondary" class="secondary" size="sm" v-on:click='logout'>Logout</b-button></b-col>
              <b-col cols="6" sm="4" v-show="!user_logged_in"><b-button block variant="secondary" class="secondary" size="sm" v-on:click='register'>Register</b-button></b-col>
              <b-col cols="6" sm="4" v-show="user_logged_in && user_verified === false"><b-button block variant="primary" class="primary" size="sm" v-on:click='refresh'>Refresh</b-button></b-col>
            </b-row>
            <b-row>
              <b-col><hr></b-col>
            </b-row>
            <b-row class="pb-2">
              <b-col class="text-left"><b>Credits</b></b-col>
            </b-row>
            <b-row>
              <b-col class="text-left text-sm-center">
                <i>
                _ inspired by Ohad _<br>
                _ made by Ozzie _<br>
                _ dedicated to Air Berlin Sequentials _<br>
                _ licensed by Hurricane Factory Berlin _<br>
                <br>
                © 2021 Flyculator<br>
                contact: <a href="mailto:flyculator@eizzo.net">flyculator@eizzo.net</a>
                </i>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// import Splash from './components/Splash.vue'

import { db } from './db'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import ClipboardJS from 'clipboard'

export default {
  name: 'Flyculator',
  components: {
    // Splash
  },
  data () {
    return {
      DEFAULT_COST: 650,
      DEFAULT_CURRENCY: 'EUR',
      DEFAULT_THEME: 'HF',
      DEFAULT_HIDE_CONTROLS: false,
      DEFAULT_URL: 'https://flyculator.eizzo.net',

      // ENVIRONMENT VARS
      activePage: 'page_splash',
      splashForm_isVisible: false,
      flyersForm_isVisible: false,
      flyersControls_isVisible: false,
      entriesForm_isVisible: false,
      entriesControls_isVisible: false,
      sessionInitated: false,
      scroll_down: '',

      // TEXT 2 SPEECH
      readyToTalk: false,
      voices: null,
      text2speech: false, // text 2 speech on or off

      // SPLASH SCREEN
      website_link: '',

      // FLYERS CONTROLS
      isFlyerUpdate: false,
      flyerIDToUpdate: -1,
      txtFlyerName: '',
      chkFlyerIsCoach: false,

      // FLYERS
      entries: [],
      roster: [],

      // ENTRIES CONTROLS xxx
      isEntryUpdate: false,
      entryIDToUpdate: -1,
      entryIndexToUpdate: -1,
      lblNumber: -1,
      cmbEntryTime: -1,
      txtNote: '',
      flyersToAdd: [],
      flyersAdded: [],
      selectedFlyer: -1,

      // ENTRIES
      entry_times: [
        { value: 60, text: '1 min' },
        { value: 90, text: '1:30 min' },
        { value: 120, text: '2 min' },
        { value: 150, text: '2:30 min' },
        { value: 180, text: '3 min' },
        { value: 210, text: '3:30 min' },
        { value: 240, text: '4 min' },
        { value: 270, text: '4:30 min' },
        { value: 300, text: '5 min' }
      ],

      // SETTINGS
      themes: [
        { value: 'HF', text: 'Hurricane Factory', cost: 650, currency: 'EUR', website: 'https://www.hurricanefactory.com/berlin/de' },
        { value: 'ABS', text: 'Air Berlin Sequentials', cost: 650, currency: 'EUR', website: 'https://www.facebook.com/AIRBerlin-Sequentials-105765131260312/' }
        // { value: 'WOB', text: 'Windobona', cost: 500, currency: 'EUR', website: 'https://www.windobona.berlin' }
      ],
      theme: '', // EXPORT
      currencies: [
        { value: 'EUR', text: '€' },
        { value: 'USD', text: '$' },
        { value: 'GBP', text: '£' },
        { value: 'JPY', text: '¥' }
      ],
      currency: '', // EXPORT
      cost: -1, // EXPORT
      hideControls: false, // EXPORT

      session_unsubsribe_function: null,
      session_name: '',
      session_name_loaded: '',
      session_password: '',
      session_password_loaded: '',

      link_to_copy: '',

      last_updated: '',
      last_updated_by_user_id: -1,
      last_updated_by_user: '',

      user_id: '',
      user_name: '',
      user_email: '',
      user_password: '',
      user_logged_in: false,
      user_verified: false
    }
  },
  created: function s () {
    // set the defaults to make the app work
    this.cost = this.DEFAULT_COST
    this.currency = this.DEFAULT_CURRENCY
    this.theme = this.DEFAULT_THEME
    this.hideControls = this.DEFAULT_HIDE_CONTROLS

    // check for params and check local storage for settings
    this.checkForParams()

    // if a proper session param has been provided load the session right away
    if (this.session_name !== '') {
      this.loadSession()
    }

    // TEXT 2 SPEECH
    // init SpeechSynthesis
    this.initSpeechSynthesis()

    const ref = this
    const clipboard = new ClipboardJS('.clipBtn')

    clipboard.on('success', function (e) {
      // console.info('Action:', e.action)
      // console.info('Text:', e.text)
      // console.info('Trigger:', e.trigger)
      ref.cl('The link has been copied to your clipboard.')
      ref.$swal('Ready to share', 'The link has been copied to your clipboard.', 'success')

      e.clearSelection()
    })
    clipboard.on('error', function (e) {
      // console.error('Action:', e.action)
      // console.error('Trigger:', e.trigger)
      ref.cl('Copying to the clipboard has failed.')
      ref.$swal('Oh no...', 'Copying to the clipboard has failed.', 'error')
    })

    // login-/logout-listener
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.cl('logged in')
        this.user_logged_in = true

        // set user_name and user_verified
        this.checkForDisplayName()
      } else {
        this.cl('not logged in')

        // clear User
        this.user_name = ''
        this.user_email = ''
        this.user_password = ''
        this.user_logged_in = false
        this.user_verified = false
      }
    })
  },
  updated: function s () {
    if (this.activePage === 'page_splash' && this.splashForm_isVisible) {
      this.$refs._txtSplashSessionName.focus()
    }

    if (this.scroll_down) {
      if (this.scroll_down === 'ADD_FLYER' || this.scroll_down === 'UPDATE_FLYER') {
        this.$refs._txtFlyerName.focus()

        // visual bugfix: erst wenn mehr als 2 Einträge vorhanden sind, nach unten scrollen
        if (this.roster.length > 2) {
          var el = document.getElementById('_txtFlyerName')
          el.scrollIntoView(true)
        }

        this.scroll_down = ''
      }

      // xxx delete me
      // if (this.activePage === 'page_entries' && this.entriesForm_isVisible && document.getElementById('_txtNote') !== document.activeElement) {
      //   if (this.isEntryUpdate) {
      //     this.$refs._btnEntryFormUpd.focus()
      //   } else {
      //     this.$refs._btnEntryFormAdd.focus()
      //   }

      //   window.scrollTo(0, document.body.scrollHeight)
      // }

      if (this.scroll_down === 'ADD_ENTRY' || this.scroll_down === 'UPDATE_ENTRY') {
        // xxx delete me
        // visual bugfix: auf Mobilgeräten aligne ich den Ccroll am oberen Rand des Form, auf allen anderen Geräten am unteren Rand
        // if (screen.width <= 760) {
        //   var element = document.getElementById('_txtNote')
        //   element.scrollIntoView(true)
        //   window.scrollBy(0, -80)
        // } else {
        //   window.scrollTo(0, document.body.scrollHeight)
        // }
        window.scrollTo(0, document.body.scrollHeight)

        this.scroll_down = ''
      }
    }
  },
  computed: {
    entryList: function s () {
      var e = []
      var t = 0

      for (var i = 0; i < this.entries.length; i++) {
        var coaches = []

        // find out how many coaches are in the group and who
        for (var j = 0; j < this.entries[i].flyers.length; j++) {
          // if he's a coach add him to coaches array of this entry
          if (this.getFlyerByID(this.entries[i].flyers[j]).isCoach) {
            coaches.push(this.entries[i].flyers[j])
          }
        }

        // list every flyer in every entry seperately
        for (j = 0; j < this.entries[i].flyers.length; j++) {
          var n = {
            no: ++t,
            entry: i,
            count: this.entries[i].flyers.length,
            cost: this.cost / 60 * this.entries[i].time / 60,
            time: this.entries[i].time,
            flyer: this.entries[i].flyers[j]
          }

          // divide the costs by the number of flyers minus the number of coaches
          if (this.entries[i].flyers.length !== 0) {
            n.cost = n.cost / (this.entries[i].flyers.length - coaches.length)
          }

          // coaches don't pay nothing
          if (this.getFlyerByID(this.entries[i].flyers[j]).isCoach) {
            n.cost = 0
          }

          e.push(n)
        }
      }

      return e
    }
  },
  methods: {
    checkForParams: function s () {
      const urlParams = new URLSearchParams(window.location.search)
      const t = urlParams.get('t') // theme
      const s = urlParams.get('s') // session_name

      // check if there is a param for theme
      if (t && t.trim() !== '') {
        // a param for theme has been provided

        // if param for theme does not exists in themes-array fallback to DEFAULT_THEME
        if (this.themes.filter(obj => { return obj.value === t.toUpperCase() }).length > 0) {
          this.theme = t.toUpperCase()
        } else {
          this.theme = this.DEFAULT_THEME
        }
      } else {
        // a param has not been provided so check if there is something stored in local storage instead

        if (localStorage && localStorage.theme && localStorage.theme !== '') {
          this.theme = localStorage.theme
          this.cost = localStorage.cost
          this.currency = localStorage.currency
        }
      }

      this.setSettingsByTheme()

      if (s && s.trim() !== '') {
        this.session_name = s
      }
    },
    setSettingsByTheme: function s () {
      var obj = this.themes.filter(obj => { return obj.value === this.theme })[0]

      this.cost = obj.cost
      this.currency = obj.currency
      this.website_link = obj.website
    },

    // ### NAVIGATION ###
    showSplashPage: function s () {
      this.splashForm_isVisible = false
      this.activePage = 'page_splash'
      window.scrollTo(0, 0)
    },
    showFlyersPage: function s () {
      // if the user entered a value for session_name on the splash screen without pressing the load-button, value must be reset from session_name_loaded
      this.session_name = this.session_name_loaded

      this.flyersForm_isVisible = false
      this.flyersControls_isVisible = false
      this.activePage = 'page_flyers'
      window.scrollTo(0, 0)
    },
    showEntriesPage: function s () {
      // if the user entered a value for session_name on the splash screen without pressing the load-button, value must be reset from session_name_loaded
      this.session_name = this.session_name_loaded

      this.entriesForm_isVisible = false
      this.entriesControls_isVisible = false
      this.activePage = 'page_entries'
      window.scrollTo(0, 0)
    },
    showSettingsPage: function s () {
      // if the user entered a value for session_name on the splash screen without pressing the load-button, value must be reset from session_name_loaded
      this.session_name = this.session_name_loaded

      this.activePage = 'page_settings'
      window.scrollTo(0, 0)
    },

    // ### SPLASH SCREEN ###
    createSession: function s () {
      // clear all vars
      // xxx vollständig??
      this.roster = []
      this.entries = []
      this.last_updated = ''
      this.last_updated_by_user_id = -1
      this.last_updated_by_user = ''

      this.session_id = ''
      this.session_name = ''
      this.session_name_loaded = ''
      this.session_password = ''
      this.session_password_loaded = ''

      this.save_allowed = false
      this.update_allowed = false

      // flag to indicate that all vars have been set
      this.sessionInitated = true

      // go to flyers page
      this.showFlyersPage()
    },
    showSessionLoader: function s () {
      // xxx brauche ich diese funktion überhaupt?
      this.splashForm_isVisible = !this.splashForm_isVisible
    },
    loadAndListen: function s (sessionName) {
      const ref = this

      this.session_unsubsribe_function = db.collection('sessions').where('session_name_lowercase', '==', sessionName.toLowerCase()).where('is_visible', '==', true).onSnapshot((s) => {
        s.forEach(function (s) {
          // parse data
          var e = s.data().json_data
          e = JSON.parse(e)

          // overwrite local data
          ref.cost = e.cost
          ref.currency = e.currency
          ref.theme = e.theme
          ref.hideControls = e.hideControls
          ref.roster = e.roster
          ref.entries = e.entries

          ref.session_id = s.id
          ref.session_name = s.data().session_name
          ref.session_name_loaded = s.data().session_name
          ref.session_password_loaded = s.data().session_password

          // for some reason last_updated becomes null at some point but will be overwritten right away
          if (s.data().date_updated) {
            ref.last_updated = new Date(s.data().date_updated.seconds * 1000)
          }

          ref.last_updated_by_user_id = s.data().updated_by_user_id
          ref.last_updated_by_user = s.data().updated_by_user

          // xxx
          ref.cl('Snapshot listener fired: Data has been updated.')

          // if the user goes to the splash screen to load another session show alert-box that the data has been updated
          if (ref.sessionInitated && ref.activePage === 'page_splash') {
            ref.$swal('Loading complete', 'Session data has been loaded.')
          }

          // if this is the first time the data is loaded set flag and go to flyers page
          if (!ref.sessionInitated) {
            ref.sessionInitated = true
            ref.showFlyersPage()
          }
        })
      })
    },
    loadSession: function s () {
      // no trailing spaces
      var sessionName = this.session_name.trim()

      // validate input
      if (sessionName === '' || sessionName.length < 3) {
        this.$swal('Name is too short', 'Session name must have at least 3 characters.', 'warning')
        return
      }

      // clear session password
      this.session_password = ''

      // if user reloads a session alert will be shown and input box must be hidden
      this.splashForm_isVisible = false

      // if exists unsubscribe from old session
      if (this.session_unsubsribe_function) {
        this.session_unsubsribe_function()
      }

      // load and subscribe (listen) to new session
      this.loadAndListen(sessionName)
    },

    // ### FLYERS PAGE ###
    prepareAddFlyer: function s () {
      // reset controls
      this.txtFlyerName = ''
      this.chkFlyerIsCoach = false

      this.isFlyerUpdate = false
      this.flyerIDToUpdate = -1
      this.flyersForm_isVisible = true

      // scroll down to form
      this.scroll_down = 'ADD_FLYER'
    },
    addFlyer: function s () {
      const flyerName = this.txtFlyerName.trim()

      // Validation: 1 char minimum
      if (flyerName === '') {
        this.$swal('Name is missing', 'Please enter the name of the flyer.', 'warning')
        return
      }

      // TEXT 2 SPEECH
      if (flyerName.toLowerCase() === '#lzb') {
        if (this.text2speech) {
          // deactivate text to speech
          this.cl('Lazybirdz mode off.')
        } else {
          // activate text to speech
          this.cl('Lazybirdz mode on.')
        }
        this.text2speech = !this.text2speech
        this.txtFlyerName = ''
        return
      }
      if (this.text2speech && this.readyToTalk) {
        switch (flyerName.toLowerCase()) {
          case 'raif':
            this.speak('de', 'Ruft schon mal den Krankenwagen! Reif ist da!')
            break
          case 'ale':
            this.speak('it', 'Vaffan napoli!')
            break
          case 'wulle':
          case 'basti w.':
          case 'basti w':
          case 'sebastian w.':
          case 'sebastian w':
            this.speak('de', 'Wulle Wulle Wulle Wulle Wulle Wulle Wulle!')
            break
          case 'madelaine':
            this.speak('es', 'Que mujer tan cachonda!')
            break
          case 'kon':
            this.speak('en', 'Kon Air is in the house!')
            break
          case 'ohad':
            this.speak('de', 'Das ist der Typ, der ein O hat.')
            break
          case 'paula':
            this.speak('de', 'Pauli, Paula, Pauler!')
            break
          case 'mahle':
            this.speak('de', 'Papa Schlumpf ist mit dabei!')
            break
          case 'marcus':
            this.speak('en', 'So awesome!')
            break
          case 'ozzie':
            this.speak('de', 'Keine Angst! Der will nur spielen!')
            break
          case 'stefan':
            this.speak('fr', 'bonjour mon capitaine!')
            break
          case 'bina':
            this.speak('de', 'Das Bienchen ist auch mit dabei!')
            break
          case 'niko':
          case 'nikolai':
            this.speak('ru', 'медведь счастья здесь!')
        }
      }

      var e = this.roster

      // get ID of last flyer in Roster array or start with ID = 100
      var t = e.length > 0 ? e[e.length - 1]._id : 100

      // add flyer to Roster array
      e.push({
        _id: t + 1,
        name: flyerName,
        isCoach: this.chkFlyerIsCoach,
        hasPaid: false
      })

      // reset controls
      // this.txtFlyerName = ''
      // this.chkFlyerIsCoach = false
      // this.flyersForm_isVisible = false

      // to enter flyers easier the form doesn't close after adding
      this.prepareAddFlyer()
    },
    prepareUpdateFlyer: function s (id) {
      var e = this.roster

      // save flyer ID for update function
      this.flyerIDToUpdate = id

      // find flyer object by ID
      var flyer = e.find(function (f, index) {
        if (f._id === id) {
          return true
        }
      })

      // fill controls with data
      this.txtFlyerName = flyer.name
      this.chkFlyerIsCoach = flyer.isCoach

      // show update form
      this.isFlyerUpdate = true
      this.flyersForm_isVisible = true

      // scroll down to form
      this.scroll_down = 'UPDATE_FLYER'
    },
    updateFlyer: function s () {
      // Validation: 1 char minimum
      if (this.txtFlyerName.trim() === '') {
        this.$swal('Name is missing', 'Please enter the name of the flyer.', 'warning')
        return
      }

      var e = this.roster
      var id = this.flyerIDToUpdate

      // find the index of the flyer to be updated
      var flyerIndex = e.findIndex(function (f, index) {
        if (f._id === id) {
          return true
        }
      })

      // don't see why this could ever be -1 but hey...
      if (flyerIndex >= 0) {
        // update flyer
        e[flyerIndex].name = this.txtFlyerName
        e[flyerIndex].isCoach = this.chkFlyerIsCoach
      } else {
        this.cl('update failed')
      }

      // reset controls
      this.txtFlyerName = ''
      this.chkFlyerIsCoach = false

      // hide update-form
      this.isFlyerUpdate = false
      this.flyerIDToUpdate = -1
      this.flyersForm_isVisible = false
    },
    deleteFlyer: function s () {
      var e = this.roster
      var id = this.flyerIDToUpdate
      var ref = this

      // find the index of the flyer to be deleted
      var flyerIndex = e.findIndex(function (f, index) {
        if (f._id === id) {
          return true
        }
      })

      // check entryList if flyer is listed in an entry
      var flyerInEntry = false
      for (var i = 0; i < this.entryList.length; i++) {
        if (this.entryList[i].flyer === id) {
          // flyer is in an entry
          flyerInEntry = true
          break
        }
      }

      // if the flyer is still listed in an entry deletion is not allowed
      if (flyerInEntry) {
        this.$swal('Sorry, but no!', 'Flyer can\'t be deleted because he/she is listed in an entry.', 'error')
        return
      }

      // show confirmation dialog
      this.$swal({
        title: 'Are you sure?',
        text: 'You can\'t revert your action.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          e.splice(flyerIndex, 1)

          // if this was the last flyer in the roster hide controls
          if (e.length === 0) {
            ref.flyersControls_isVisible = false
          }

          // reset controls
          ref.txtFlyerName = ''
          ref.chkFlyerIsCoach = false

          // hide update-form
          ref.isFlyerUpdate = false
          ref.flyerIDToUpdate = -1
          ref.flyersForm_isVisible = false
        }
      })
    },
    setFlyerPaid: function s (id) {
      var e = this.roster

      // find the index of the flyer to be updated
      var flyerIndex = e.findIndex(function (f, index) {
        if (f._id === id) {
          return true
        }
      })

      e[flyerIndex].hasPaid = !e[flyerIndex].hasPaid
    },
    getFlyerTotalsByID: function s (id) {
      var t = {
        entries: 0,
        time: 0,
        costs: 0,
        costPerMin: 0
      }

      for (var n = 0; n < this.entryList.length; n++) {
        if (Number(this.entryList[n].flyer) === id) {
          t.entries++
          t.time += this.entryList[n].time
          t.costs += this.entryList[n].cost
        }
      }

      if (t.time > 0) {
        t.costPerMin = t.costs / (t.time / 60)
      } else {
        t.costPerMin = 0
      }

      return t
    },
    getTotals: function s () {
      var t = {
        entries: this.entries.length,
        time: 0,
        costs: 0,
        costPerMin: 0
      }

      for (var n = 0; n < this.entries.length; n++) {
        t.time += this.entries[n].time
      }

      t.costs = (this.cost / 60) * (t.time / 60)

      if (t.time > 0) {
        t.costPerMin = t.costs / (t.time / 60)
      } else {
        t.costPerMin = 0
      }

      return t
    },
    flyerSubmit: function s () {
      if (this.isFlyerUpdate) {
        this.updateFlyer()
      } else {
        this.addFlyer()
      }
    },

    // ### ENTRIES PAGE ###
    prepareAddEntry: function s () {
      // reset controls
      this.lblNumber = '#' + (this.entries.length + 1)
      this.cmbEntryTime = this.entries.length > 0 ? this.entries[this.entries.length - 1].time : 150
      this.txtNote = ''
      this.flyersAdded = []
      this.flyersToAdd = []

      // for every flyer in roster find number of entries in entryList and add flyer-object to array "flyersToAdd"
      for (var i = 0; i < this.roster.length; i++) {
        var flyer = {
          _id: this.roster[i]._id,
          name: this.roster[i].name,
          isCoach: this.roster[i].isCoach,
          entries: 0
        }

        // check entryList for occurances of this flyer
        for (var j = 0; j < this.entryList.length; j++) {
          if (this.entryList[j].flyer === flyer._id) {
            flyer.entries++
          }
        }

        this.flyersToAdd.push(flyer)
      }

      // sort array by name
      this.flyersToAdd.sort((a, b) => (a.name > b.name) ? 1 : (b.name > a.name) ? -1 : 0)

      this.isEntryUpdate = false
      this.entryIDToUpdate = -1
      this.entryIndexToUpdate = -1

      // Form anzeigen
      this.entriesForm_isVisible = true

      // scroll to form
      this.scroll_down = 'ADD_ENTRY'
    },
    addEntry: function s (index) {
      // put the IDs of the flyersAdded in an array
      var fa = []
      for (var i = 0; i < this.flyersAdded.length; i++) {
        fa.push(this.flyersAdded[i]._id)
      }

      // find ID of last added entry
      var lastEntryID = -1
      for (var j = 0; j < this.entries.length; j++) {
        if (this.entries[j]._id > lastEntryID) {
          lastEntryID = this.entries[j]._id
        }
      }

      var obj = {
        flyers: fa,
        _id: lastEntryID + 1,
        note: this.txtNote,
        time: this.cmbEntryTime,
        isMarked: false
      }

      this.entries.push(obj)

      // reset controls
      // this.lblNumber = '#' + (this.entries.length + 1)
      // this.txtNote = ''
      // this.removeAllFlyersFromEntry()
      // this.entriesForm_isVisible = false

      // to enter flyers easier the form doesn't close after adding
      this.prepareAddEntry()
    },
    addFlyerToEntry: function s (index) {
      var obj = this.flyersToAdd[index]

      // delete flyer from array "flyersToAdd"
      this.flyersToAdd.splice(index, 1)

      // flyer has one more entry now
      obj.entries++

      // add flyer to array "flyersAdded"
      this.flyersAdded.push(obj)
    },
    addAllFlyersToEntry: function s () {
      // add all flyers to new array
      for (var i = 0; i < this.flyersToAdd.length; i++) {
        var obj = this.flyersToAdd[i]

        // flyer has one more entry now
        obj.entries++

        this.flyersAdded.push(obj)
      }

      // clear old array
      this.flyersToAdd = []
    },
    removeFlyerFromEntry: function s (index) {
      var obj = this.flyersAdded[index]

      // delete flyer from array "flyersToAdd"
      this.flyersAdded.splice(index, 1)

      // flyer has one less entry now
      obj.entries--

      // add flyer to array "flyersAdded"
      this.flyersToAdd.push(obj)

      // re-sort that array
      this.flyersToAdd.sort((a, b) => (a.name > b.name) ? 1 : (b.name > a.name) ? -1 : 0)
    },
    removeAllFlyersFromEntry: function s () {
      // add all flyers to new array
      for (var i = 0; i < this.flyersAdded.length; i++) {
        var obj = this.flyersAdded[i]

        // flyer has one less entry now
        obj.entries--

        // add flyer to array "flyersToAdd"
        this.flyersToAdd.push(obj)
      }

      // re-sort that array
      this.flyersToAdd.sort((a, b) => (a.name > b.name) ? 1 : (b.name > a.name) ? -1 : 0)

      // clear old array
      this.flyersAdded = []
    },
    markEntry: function s (index) {
      var lastIndex = -1

      // get the index of the last marked entry
      for (var j = 0; j < this.entries.length; j++) {
        if (this.entries[j].isMarked) {
          lastIndex = j
        }
      }

      // if same entry is clicked unmark all entries
      if (lastIndex === index) {
        // same entry has been clicked
        for (var k = 0; k < this.entries.length; k++) {
          this.entries[k].isMarked = false
        }
      } else {
        // mark all entries below the index and at the index itself
        for (var i = 0; i < this.entries.length; i++) {
          this.entries[i].isMarked = (i <= index)
        }
      }
    },
    moveToTop: function s (index) {
      this.entries.unshift(this.entries.splice(index, 1)[0])
    },
    moveUp: function s (index) {
      if (index === 0) {
        this.entries.push(this.entries.shift()) // move item to the back of array
      } else {
        this.entries = this.entries.map((element, i) => { // iterate through array and change places
          if (i === index - 1) {
            return this.entries[index]
          } else if (i === index) {
            return this.entries[index - 1]
          } else {
            return element
          }
        })
      }
    },
    moveDown: function s (index) {
      if (index === this.entries.length - 1) {
        this.entries.unshift(this.entries.pop()) // move item to the front of array
      } else {
        this.entries = this.entries.map((element, i) => { // iterate trough array and change places
          if (i === index) {
            return this.entries[index + 1]
          } else if (i === index + 1) {
            return this.entries[index]
          } else {
            return element
          }
        })
      }
    },
    moveToBottom: function s (index) {
      this.entries.push(this.entries.splice(index, 1)[0])
    },
    duplicateEntry: function s (index) {
      // split array in two at index
      var a = this.entries.slice(0, index)
      var b = this.entries.slice(index)
      var c = JSON.parse(JSON.stringify(b)) // deep copy array "b"

      // find ID of last added entry
      var lastEntryID = -1
      for (var i = 0; i < this.entries.length; i++) {
        if (this.entries[i]._id > lastEntryID) {
          lastEntryID = this.entries[i]._id
        }
      }

      // select item to be duplicated and assign new ID to it
      var obj = c[0]
      obj._id = lastEntryID + 1

      // add duplicate item to array
      a.push(obj)

      // put both arrays together
      this.entries = a.concat(b)
    },
    selectFlyer: function s (id) {
      if (this.selectedFlyer === id) {
        // deselect this flyer
        this.selectedFlyer = -1
      } else {
        this.selectedFlyer = id
      }
    },
    prepareUpdateEntry: function s (index) {
      // reset controls
      this.lblNumber = '#' + (index + 1)
      this.cmbEntryTime = this.entries[index].time
      this.txtNote = this.entries[index].note
      this.flyersAdded = []
      this.flyersToAdd = []

      // for every flyer in roster check if the ID is in this entry's flyer-array and add it to the according array
      for (var i = 0; i < this.roster.length; i++) {
        var flyerInEntry = false
        var flyer = {
          _id: this.roster[i]._id,
          name: this.roster[i].name,
          isCoach: this.roster[i].isCoach,
          entries: 0
        }

        // check entryList for occurances of this flyer to determine the number of entries
        for (var j = 0; j < this.entryList.length; j++) {
          if (this.entryList[j].flyer === flyer._id) {
            flyer.entries++
          }
        }

        // check flyer-array in this entry to decide to which array this flyer has to be added
        for (var k = 0; k < this.entries[index].flyers.length; k++) {
          if (this.entries[index].flyers[k] === flyer._id) {
            this.flyersAdded.push(flyer)
            flyerInEntry = true
            break
          }
        }

        // flyer is not in this entries flyer-array and will be added to the according array
        if (!flyerInEntry) {
          this.flyersToAdd.push(flyer)
        }
      }

      // sort array by name
      this.flyersToAdd.sort((a, b) => (a.name > b.name) ? 1 : (b.name > a.name) ? -1 : 0)

      this.isEntryUpdate = true
      this.entryIDToUpdate = this.entries[index]._id
      this.entryIndexToUpdate = index

      // show form
      this.entriesForm_isVisible = true

      // scroll down to form
      this.scroll_down = 'UPDATE_ENTRY'
    },
    updateEntry: function s () {
      // put the IDs of the flyersAdded in an array
      var fa = []
      for (var i = 0; i < this.flyersAdded.length; i++) {
        fa.push(this.flyersAdded[i]._id)
      }

      this.entries[this.entryIndexToUpdate].flyers = fa
      this.entries[this.entryIndexToUpdate].time = this.cmbEntryTime
      this.entries[this.entryIndexToUpdate].note = this.txtNote

      // reset controls
      this.lblNumber = '#'
      this.txtNote = ''
      this.cmbEntryTime = 150
      this.flyersAdded = []
      this.flyersToAdd = []
      this.isEntryUpdate = false
      this.entryIDToUpdate = -1
      this.entryIndexToUpdate = -1

      // hide form
      this.entriesForm_isVisible = false
    },
    deleteEntry: function s () {
      var index = this.entryIndexToUpdate
      var ref = this

      // show confirmation dialog
      this.$swal({
        title: 'Are you sure?',
        text: 'You can\'t revert your action.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          ref.entries.splice(index, 1)

          // if this was the last entry hide controls
          if (ref.entries.length === 0) {
            ref.entriesControls_isVisible = false
          }

          // reset controls
          ref.lblNumber = '#'
          ref.txtNote = ''
          ref.cmbEntryTime = 150
          ref.flyersAdded = []
          ref.flyersToAdd = []
          ref.isEntryUpdate = false
          ref.entryIDToUpdate = -1
          ref.entryIndexToUpdate = -1

          // hide form
          ref.entriesForm_isVisible = false
        }
      })
    },

    getFlyerByID: function s (id) {
      var t = this.roster

      for (var i = 0; i < t.length; i++) {
        if (Number(t[i]._id) === Number(id)) {
          return t[i]
        }
      }
    },
    getFlyerNameByID: function s (id) {
      var t = this.roster

      for (var i = 0; i < t.length; i++) {
        if (Number(t[i]._id) === Number(id)) {
          if (t[i].isCoach) {
            return '<b>' + t[i].name + '</b>'
          } else {
            return t[i].name
          }
        }
      }
    },
    getFlyerNamesByID: function s (arr) {
      var t = []

      for (var n = 0; n < arr.length; n++) {
        t.push(this.getFlyerNameByID(arr[n]))
      }
      return t.join(', ')
    },

    // ### SETTINGS PAGE ###
    login: function s () {
      const email = this.user_email
      const password = this.user_password
      const auth = firebase.auth()
      const ref = this

      // validation
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (email.trim() === '' || !regex.test(email)) {
        this.$swal('Invalid Email', 'Please enter a valid email address.', 'warning')
        this.$refs.txtEmail.focus()
        return
      }
      if (password.trim() === '' || password.trim().length < 6) {
        this.$swal('Password too short', 'Please enter a password with at least 6 characters.', 'warning')
        this.$refs.txtPassword.focus()
        return
      }

      // clear user name so it can be set from the db
      this.user_name = ''

      auth.signInWithEmailAndPassword(email, password).catch(function (error) {
        if (error.code === 'auth/wrong-password') {
          // Handle Errors here.
          ref.$swal('Login failed', 'Couldn\'t login. Password does not match.', 'error')
        }

        ref.cl(error.message)
      })
    },
    logout: function s () {
      const auth = firebase.auth()

      auth.signOut()
    },
    register: async function s () {
      const email = this.user_email
      const password = this.user_password
      const ref = this

      // validation
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (email.trim() === '' || !regex.test(email)) {
        this.$swal('Invalid Email', 'Please enter a valid email address.', 'warning')
        this.$refs.txtEmail.focus()
        return
      }
      if (password.trim() === '' || password.trim().length < 6) {
        this.$swal('Password too short', 'Please enter a password with at least 6 characters.', 'warning')
        this.$refs.txtPassword.focus()
        return
      }

      const { value: username } = await this.$swal({
        title: 'Enter your name',
        input: 'text',
        inputLabel: 'Attention: Your name can\'t be changed later!',
        inputPlaceholder: 'Your first- and lastname'
      })

      if (!username || username.trim() === '') {
        this.$swal('Oops...', 'You must enter your name to register.', 'error')
        return
      }

      await firebase.auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async function (user) {
          if (user && user.user.emailVerified === false) {
            // set display_name before sending email
            await user.user.updateProfile({ displayName: username })
            ref.user_name = username + ' (not verified)'

            await user.user.sendEmailVerification().then(function () {
              ref.cl('email verification sent to user')

              ref.$swal('Almost done!', 'Please click on the verification link in the email we just sent you.\nAfter that just press the refresh button on the settings page.', 'info')
            }).catch(function (error) {
              // Handle Errors here.
              ref.cl(error)

              ref.$swal('Oops...', 'Couldn\'t send verification email.\n Error: ' + error.message, 'error')
            })
          }
        }).catch(function (error) {
          // Handle Errors here.
          ref.cl(error)

          if (error.code === 'auth/email-already-in-use') {
            ref.$swal('Email already registerd', 'This email address is already taken. Please use another one.', 'error')
          }
        })
    },
    resendVerification: function s () {
      const user = firebase.auth().currentUser
      const ref = this

      user.sendEmailVerification().then(function () {
        // Email sent.
        ref.cl('email out')
        ref.$swal('Check your mail', 'Verification link has been sent to: ' + user.email, 'info')
      }).catch(function (error) {
        // An error happened.
        ref.cl(error)
        ref.$swal('Oops...', 'Couldn\'t send verification email.\n Error: ' + error.message, 'error')
      })
    },
    resetPassword: function s () {
      const auth = firebase.auth()
      const email = this.user_email
      const ref = this

      // email validation
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (email.trim() === '' || !regex.test(email)) {
        this.$swal('Invalid Email', 'Please enter a valid email address.', 'warning')
        this.$refs.txtEmail.focus()
        return
      }

      auth.sendPasswordResetEmail(email).then(function () {
        // Email sent.
        ref.cl('email out')
        ref.$swal('Check your mails', 'A link to reset your password has been sent to: ' + email, 'info')
      }).catch(function (error) {
        // An error happened.
        ref.cl(error)

        if (error.code === 'auth/user-not-found') {
          ref.$swal('User not found', 'Sorry! Couldn\'t find a user with this email address.', 'error')
        }
      })
    },
    refresh: async function s () {
      var user = firebase.auth().currentUser
      var email = this.user_email
      var password = this.user_password

      if (!email || email === '') {
        const { value: emailPrompt } = await this.$swal({
          title: 'Enter your email address',
          input: 'email',
          inputLabel: 'Please enter the email address you used to register.',
          inputPlaceholder: 'Your email address',
          showCancelButton: true
        })

        if (!emailPrompt || emailPrompt.trim() === '') {
          return
        } else {
          email = emailPrompt
        }
      }

      if (!password || password === '') {
        const { value: passwordPrompt } = await this.$swal({
          title: 'Enter your password',
          input: 'password',
          inputLabel: 'Please enter the password you used to register.',
          inputPlaceholder: 'Your password',
          inputAttributes: {
            maxlength: 30,
            autocapitalize: 'off',
            autocorrect: 'off'
          },
          showCancelButton: true
        })

        if (!passwordPrompt || passwordPrompt.trim() === '') {
          return
        } else {
          password = passwordPrompt
        }
      }

      var credential = firebase.auth.EmailAuthProvider.credential(email, password)
      var ref = this

      await user.reauthenticateWithCredential(credential).then(async function () {
        ref.user_name = ''

        // User re-authenticated
        ref.checkForDisplayName()

        if (ref.user_verified) {
          await ref.$swal('Hooray', 'You have been logged in and verified. You can now save and update sessions online', 'success')
        } else {
          await ref.$swal('Oops...', 'Couldn`t verify you. Please click on the verification link in the email sent to you.', 'error')
        }
      }).catch(function (error) {
        // An error happened.
        ref.cl(error)

        ref.$swal('Oops...', 'Couldn`t verify you. Error: ' + error.message, 'error')
      })
    },
    generateName: function s () {
      var length = 12
      var charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      var retVal = ''

      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n))
      }
      this.session_name = retVal
    },
    save: async function s () {
      const ref = this
      var continueSave

      // validation first
      const sessionName = this.session_name.trim()
      if (sessionName === '' || sessionName.length < 3) {
        ref.$swal('Name too short', 'Session name must have at least 3 characters.', 'error')
        return
      }
      if (sessionName.includes('#')) {
        ref.$swal('Invalid character', 'Session name must not contain a \'#\' character.', 'error')
        return
      }
      // password is not mandatory (so far)
      if (this.session_password.trim() === '') {
        await this.$swal({
          title: 'No password?',
          text: 'If you don\'t provide a password everybody can make changes. This may result in simultaneous updates and inconsistant data.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Continue',
          cancelButtonText: 'Cancel'
        }).then((result) => {
          if (result.value) {
            continueSave = true
          } else {
            continueSave = false
          }
        })

        if (!continueSave) {
          return
        }
      }
      // "cost" must be a valid number
      if (isNaN(this.cost)) {
        ref.$swal('Invalid input', 'Cost must be a number or a decimal.', 'error')
        return
      }

      // first assume everything's fine and then do the checks
      this.save_allowed = true // is true if there is no session with this name already
      this.update_allowed = false // is true if the save is actually an update

      // if a session with this name already exists check if password matches - if so overwrite
      db.collection('sessions').where('session_name_lowercase', '==', ref.session_name.trim().toLowerCase()).get().then(function (s) {
        s.forEach(function (s) {
          // a session with this name exists
          ref.save_allowed = false

          // check password
          if (s.data().session_password === ref.session_password) {
            ref.save_allowed = true
            ref.update_allowed = true
            ref.session_id = s.id
            ref.cl('Overwriting existing session')
          }
        })

        return ref
      }).then(function (ref) {
        if (ref.save_allowed) {
          // construct json
          const json = {
            entries: ref.entries,
            roster: ref.roster,
            theme: ref.theme,
            cost: ref.cost,
            currency: ref.currency,
            hideControls: ref.hideControls
          }

          // update or save new session
          if (ref.update_allowed) {
            // update

            // Add a new document with a generated id.
            db.collection('sessions').doc(ref.session_id).update({
              date_updated: firebase.firestore.FieldValue.serverTimestamp(),
              json_data: JSON.stringify(json),
              updated_by_user: ref.user_name,
              updated_by_user_id: ref.user_id
            }).then(function (docRef) {
              ref.cl('An existing session has been overwritten.')

              // if exists unsubscribe from old session
              if (ref.session_unsubsribe_function) {
                ref.session_unsubsribe_function()
              }

              // load and subscribe to new session
              ref.loadAndListen(ref.session_name.trim())
            }).catch(function (error) {
              ref.cl('Error overwriting existing session!\n' + error.message)
              ref.$swal('Oops...', 'Session could not be saved.\nError: ' + error.message, 'error')
            })
          } else {
            // save new

            // Add a new document with a generated id.
            db.collection('sessions').add({
              date_created: firebase.firestore.FieldValue.serverTimestamp(),
              date_updated: firebase.firestore.FieldValue.serverTimestamp(),
              is_visible: true,
              json_data: JSON.stringify(json),
              session_name: ref.session_name,
              session_name_lowercase: ref.session_name.trim().toLowerCase(),
              session_password: ref.session_password,
              updated_by_user: ref.user_name,
              updated_by_user_id: ref.user_id
            }).then(function (docRef) {
              ref.cl('Session has been saved.')

              // if exists unsubscribe from old session
              if (ref.session_unsubsribe_function) {
                ref.session_unsubsribe_function()
              }

              // load and subscribe to new session
              ref.loadAndListen(ref.session_name.trim())
            }).catch(function (error) {
              ref.cl('Error saving session!\n' + error.message)
            })
          }
        } else {
          ref.$swal('Could\'t save', 'Session name is already taken and password does not match!', 'error')
        }
      }).catch(function (error) {
        // Handle Errors here.
        ref.cl(error.message)

        ref.$swal('Oops...', 'Something went wrong.\nError: ' + error.message, 'error')
      })
    },
    update: function s () {
      const ref = this

      // "cost" must be a valid number
      if (isNaN(this.cost)) {
        ref.$swal('Invalid input', 'Cost must be a number or a decimal.', 'error')
        return
      }

      // first assume he doen't have the permission to change and then do the checks
      this.save_allowed = false

      db.collection('sessions').where('session_name_lowercase', '==', ref.session_name.trim().toLowerCase()).where('is_visible', '==', true).get().then(function (s) {
        s.forEach(function (s) {
          // a session with this name exists
          ref.save_allowed = false

          // check password
          if (s.data().session_password === ref.session_password) {
            ref.save_allowed = true
            ref.cl('Updating session')
          } else {
            ref.$swal('Update failed', 'Password doesn\'t match.', 'error')
            ref.cl('Update failed. Password does not match')
          }
        })

        return ref
      }).then(function (ref) {
        if (ref.save_allowed) {
          // construct json
          const json = {
            entries: ref.entries,
            roster: ref.roster,
            theme: ref.theme,
            cost: ref.cost,
            currency: ref.currency,
            hideControls: ref.hideControls
          }

          // update document with the given ID
          db.collection('sessions').doc(ref.session_id).update({
            date_updated: firebase.firestore.FieldValue.serverTimestamp(),
            json_data: JSON.stringify(json),
            updated_by_user: ref.user_name,
            updated_by_user_id: ref.user_id
          }).then(function (docRef) {
            ref.cl('Session has been updated.')
            ref.$swal('Session updated', 'Data has been distributed to all active users.', 'success')
          }).catch(function (error) {
            ref.cl('Error updating session!\n' + error.message)

            ref.$swal('Update failed', 'Something went wrong.\nError: ' + error.message, 'error')
          })
        }
      }).catch(function (error) {
        // Handle Errors here.
        ref.cl(error)

        ref.$swal('Oops...', 'Something went wrong.\nError: ' + error.message, 'error')
      })
    },
    copyLink: function s () {
      this.link_to_copy = this.DEFAULT_URL + '/?t=' + this.theme + '&s=' + encodeURIComponent(this.session_name).replaceAll("'", '%27')
    },
    generateQRCode: async function s () {
      // alert('xxxxx')
      var imageSize = 270
      var url = encodeURIComponent(this.DEFAULT_URL + '/?t=' + this.theme + '&s=') + encodeURIComponent(this.session_name).replaceAll("'", '%27')

      await this.$swal({
        title: 'Session Name: ' + this.session_name,
        text: 'Scan QR code to load the session',
        imageUrl: 'https://api.qrserver.com/v1/create-qr-code/?size=' + imageSize + 'x' + imageSize + '&data=' + url,
        imageWidth: imageSize,
        imageHeight: imageSize,
        confirmButtonText: 'OK',
        cancelButtonText: 'No'
      })
    },
    onThemeChange: function s () {
      this.setSettingsByTheme()

      // update local storage
      this.writeToLocalStorage()
    },
    writeToLocalStorage: function s () {
      localStorage.setItem('theme', this.theme)
      localStorage.setItem('cost', this.cost)
      localStorage.setItem('currency', this.currency)
      localStorage.setItem('hideControls', this.hideControls)

      this.cl('Settings written to local storage.')
    },

    // ### HELPERS ###
    cl: function s (e) {
      console.log(e)
    },
    formatCurrency: function s (e) {
      return isNaN(e) ? -1 : Number(Math.round(e * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2) + ' ' + this.currencies.filter(obj => { return obj.value === this.currency })[0].text
    },
    formatTime: function s (e) {
      const t = new Date(e)
      var month
      switch (t.getMonth() + 1) {
        case 1:
          month = 'Jan'
          break
        case 2:
          month = 'Feb'
          break
        case 3:
          month = 'Mar'
          break
        case 4:
          month = 'Apr'
          break
        case 5:
          month = 'May'
          break
        case 6:
          month = 'Jun'
          break
        case 7:
          month = 'Jul'
          break
        case 8:
          month = 'Aug'
          break
        case 9:
          month = 'Sep'
          break
        case 10:
          month = 'Oct'
          break
        case 11:
          month = 'Nov'
          break
        case 12:
          month = 'Dec'
      }

      return t.getDate() + ' ' + month + ' ' + t.getFullYear() + ' @ ' + t.getHours() + ':' + ('0' + t.getMinutes()).slice(-2)
    },
    formatMinutes: function s (sec) {
      return Math.floor(sec / 60) + ':' + String('0' + (sec % 60)).slice(-2)
    },
    checkForDisplayName: function s () {
      const user = firebase.auth().currentUser
      var username = ''

      if (user.emailVerified) {
        this.user_verified = true
      } else {
        this.user_verified = false
      }

      // active user_name is empty after refresh
      if (!this.user_name) {
        if (user) {
          if (user.displayName) {
            username = user.displayName

            if (!this.user_verified) {
              username = username + ' (not verified)'
            }
          }
        }

        this.user_name = username
      }

      if (user.uid) {
        this.user_id = user.uid
      }
    },

    // TEXT 2 SPEECH
    initSpeechSynthesis: function s () {
      const ref = this

      if ('speechSynthesis' in window) {
        this.loadVoices()

        // Chrome loads voices asynchronously
        window.speechSynthesis.onvoiceschanged = function (e) {
          ref.loadVoices()
        }
      } else {
        this.cl('SpeechSynthesis is not supported')
      }
    },
    loadVoices: function s () {
      this.voices = speechSynthesis.getVoices()

      // if getVoices()-call is async exit function and wait for event
      if (this.voices.length === 0) {
        this.cl('voice-load initiated')
        return
      }

      // successfully loaded
      this.cl('voices have been loaded')

      // execute only once
      if (!this.readyToTalk) {
        this.readyToTalk = true // flag is set to true and will not change anymore
      }
    },
    speak: function s (language, text) {
      // exit function if voices not yet loaded
      if (!this.readyToTalk) {
        this.cl("Voices haven't been loaded yet!")
        return
      }

      // construct utterance
      var msg = new SpeechSynthesisUtterance()
      var voiceName
      var voiceLang

      // Set the attributes.
      msg.volume = 1 //   0 .. 1
      msg.rate = 1 // 0.1 .. 10
      msg.pitch = 1 //   0 .. 2

      msg.text = text

      switch (language) {
        case 'de':
          voiceName = 'Google Deutsch'
          voiceLang = 'de-DE'
          break
        case 'en':
          voiceName = 'Google US English'
          voiceLang = 'en-US'
          break
        case 'es':
          voiceName = 'Google español'
          voiceLang = 'es-ES'
          break
        case 'it':
          voiceName = 'Google italiano'
          voiceLang = 'it-IT'
          break
        case 'nl':
          voiceName = 'Google Nederlands'
          voiceLang = 'nl-NL'
          break
        case 'fr':
          voiceName = 'Google français'
          voiceLang = 'fr-FR'
          break
        case 'ru':
          voiceName = 'Google русский'
          voiceLang = 'ru-RU'
          break
        default:
          // if no language was supported fallback to german
          voiceName = 'Google Deutsch'
          voiceLang = 'de-DE'
          this.$swal('Oops...', 'Language "' + language + '" is not supported. Using german instead.', 'warning')
          this.cl('Language "' + language + '" is not supported')
      }

      // xxx i left this piece of code in case i want to add more languages and need to find out the language name and code
      // for (var o = 0; o < this.voices.length; o++) {
      //   console.log(this.voices[o].name)
      //   console.log(this.voices[o].lang)
      // }

      // try to load Google voice
      msg.voice = this.voices.filter(function (voice) {
        return voice.name === voiceName
      })[0]

      // or load fallback voice
      if (msg.voice === null) {
        msg.voice = this.voices.filter(function (voice) {
          return voice.lang === voiceLang
        })[0]
      }

      // log spoken text
      this.cl('speaking: ' + text)

      // Queue this utterance
      window.speechSynthesis.speak(msg)
    }
  },
  beforeMount () {
    // happens AFTER 'created'
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #a6a6a6;
  background-image: url('/bg.png');
  background-position: left top;
  background-repeat: repeat;
}

#navbar {
  background-color: hsl(211, 100%, 50%)
}

.splash_bg {
  background-color: #f5f5f5;
  height: 93vh;
  min-height: 730px;
}

.splash_session_bg {
  background-color: #ddd;
  border: #ccc solid 1px;
  border-radius: 6px;
}

.flyer_empty {
  background-color: #f5f5f5 !important;
  height: 12rem;
  font-size: 1.1rem;
}

table.flyer_table tr {
  border-bottom: 1px solid hsl(211, 100%, 50%);
}

table.flyer_table tr:nth-child(even) {
  background-color: hsl(0, 0%, 100%);
}

table.flyer_table tr:nth-child(odd) {
  background-color: hsl(0, 0%, 95%);
}

table.flyer_table tr.table_header td,
table.flyer_table tr.table_footer td {
  font-weight: bold;
  background-color: hsl(211, 100%, 77%);
}

.flyer_form {
  background-color: hsl(0, 0%, 75%);
}

.flyer_form_content {
  background-color: hsl(0, 0%, 86%);
}

.entry_empty {
  background-color: #f5f5f5 !important;
}

.entry_empty th {
  height: 12rem;
  font-size: 1.1rem;
}

.entry_list {
  background-color: hsl(0, 0%, 96%);
}

.entry_list .entry_marked {
  background-color: hsl(0, 0%, 87%);
}

.entry_list .entry_unmarked {
  background-color: hsl(0, 0%, 73%);
}

.entry_form {
  background-color: hsl(0, 0%, 75%) !important;
}

.entry_form_content {
  background-color: hsl(0, 0%, 80%) !important;
}

.entry_form_content .box {
  background-color: hsl(0, 0%, 93%);
  border-radius: 6px;
}

.settings_bg {
  background-color: #f5f5f5;
}

/* --------------------- HF BEGIN --------------------- */
.HF #navbar {
  background-color: hsl(40, 93%, 53%);
}

/* .HF .splash_bg {
  background-color: teal;
} */

.HF .splash_session_bg {
  background-color: #ddd;
  border: #ccc solid 1px;
}

/* .HF .flyer_empty {
  background-color: #f5f5f5 !important;
} */

.HF table.flyer_table tr {
  border-bottom: 1px solid hsl(40, 93%, 53%);
}

.HF table.flyer_table tr:nth-child(even) {
  background-color: #fff;
}

.HF table.flyer_table tr:nth-child(odd) {
  background-color: #f1f1f1;
}

.HF table.flyer_table tr.table_header td,
.HF table.flyer_table tr.table_footer td {
  background-color: hsl(40, 93%, 77%);
}

.HF .flyer_form {
  background-color: #000;
}

.HF .flyer_form_content {
  background-color: #ddd;
}

/* .HF .entry_empty {
  background-color: #f5f5f5 !important;
} */

.HF .entry_list {
  background-color: #fff;
}

.HF .entry_list .entry_marked {
  background-color: hsl(40, 93%, 62%);
  border: solid 1px hsl(40, 93%, 47%, 0.8);
}

.HF .entry_list .entry_unmarked {
  background-color: hsl(40, 93%, 80%);
  border: solid 1px hsla(40, 93%, 65%, 0.8);
}

.HF .entry_list .entry_unmarked .divider {
  border-bottom: solid 1px hsla(40, 93%, 65%, 1) !important;
}

.HF .entry_list .entry_marked .divider {
  border-bottom: solid 1px hsla(40, 93%, 47%, 1) !important;
}

.HF .entry_form {
  background-color: #000 !important;
}

.HF .entry_form_content {
  background-color: #ddd !important;
  border-top: solid 1px #bbb;
}

.HF .entry_form_content .box {
  background-color: hsl(40, 93%, 87%) !important;
  border: 1px solid hsl(40, 93%, 78%) !important;
}

/* .HF .settings_bg {
  background-color: teal;
} */

.HF .primary {
  background-color: hsl(39, 100%, 50%) !important;
  border-color: hsl(40, 93%, 53%) !important;
  color: #ffffff !important;
}

.HF .primary:hover {
  background-color: hsl(40, 93%, 45%) !important;
  border-color: hsl(40, 93%, 45%) !important;
}

.HF .secondary {
  background-color: hsl(0, 0%, 59%) !important;
  border-color: hsl(0, 0%, 59%) !important;
}

.HF .secondary:hover {
  background-color: hsl(0, 0%, 51%) !important;
  border-color: hsl(0, 0%, 51%) !important;
}

.HF .danger {
  background-color: hsl(0, 68%, 55%) !important;
  border-color: hsl(0, 68%, 55%) !important;
}

.HF .danger:hover {
  background-color: hsl(0, 68%, 47%) !important;
  border-color: hsl(0, 68%, 47%) !important;
}

.HF .outline-secondary {
  border-color: hsl(0, 0%, 59%) !important;
}

.HF .outline-secondary:hover {
  background-color: hsl(0, 0%, 51%) !important;
  border-color: hsl(0, 0%, 51%) !important;
}
/* --------------------- HF END --------------------- */

/* --------------------- WOB BEGIN --------------------- */
.WOB #navbar {
  background-color: hsl(86, 54%, 51%);
}

/* .WOB .splash_bg {
  background-color: teal;
} */

.WOB .splash_session_bg {
  background-color: #ddd;
  border: #ccc solid 1px;
}

/* .WOB .flyer_empty {
  background-color: #f5f5f5 !important;
} */

.WOB table.flyer_table tr {
  border-bottom: 1px solid hsl(86, 54%, 51%);
}

.WOB table.flyer_table tr:nth-child(even) {
  background-color: #fff;
}

.WOB table.flyer_table tr:nth-child(odd) {
  background-color: #f1f1f1;
}

.WOB table.flyer_table tr.table_header td,
.WOB table.flyer_table tr.table_footer td {
  background-color: hsl(86, 54%, 76%);
}

.WOB .flyer_form {
  background-color: rgb(88, 89, 91);
}

.WOB .flyer_form_content {
  background-color: #ddd;
}

/* .WOB .entry_empty {
  background-color: #f5f5f5 !important;
} */

.WOB .entry_list {
  background-color: #fff;
}

.WOB .entry_list .entry_marked {
  background-color: hsl(86, 54%, 57%);
  border: solid 1px hsla(86, 54%, 47%, 0.8);
}

.WOB .entry_list .entry_unmarked {
  background-color: hsl(86, 54%, 76%);
  border: solid 1px hsla(86, 54%, 59%, 0.8);
}

.WOB .entry_list .entry_unmarked .divider {
  border-bottom: solid 1px hsla(86, 54%, 59%, 1) !important;
}

.WOB .entry_list .entry_marked .divider {
  border-bottom: solid 1px hsla(86, 54%, 47%, 1) !important;
}

.WOB .entry_form {
  background-color: rgb(88, 89, 91) !important;
}

.WOB .entry_form_content {
  background-color: #ddd !important;
  border-top: solid 1px #bbb;
}

.WOB .entry_form_content .box {
  background-color: hsl(86, 54%, 89%) !important;
  border: 1px solid hsla(86, 54%, 67%, 0.9);
}

/* .WOB .settings_bg {
  background-color: teal;
} */

.WOB .primary {
  background-color: hsl(86, 54%, 51%) !important;
  border-color: hsl(86, 54%, 51%) !important;
  color: #ffffff !important;
}

.WOB .primary:hover {
  background-color: hsl(86, 54%, 44%) !important;
  border-color: hsl(86, 54%, 44%) !important;
}

.WOB .secondary {
  background-color: hsl(0, 0%, 59%) !important;
  border-color: hsl(0, 0%, 59%) !important;
}

.WOB .secondary:hover {
  background-color: hsl(0, 0%, 52%) !important;
  border-color: hsl(0, 0%, 52%) !important;
}

.WOB .danger {
  background-color: hsl(0, 68%, 55%) !important;
  border-color: hsl(0, 68%, 55%) !important;
}

.WOB .danger:hover {
  background-color: hsl(0, 68%, 47%) !important;
  border-color: hsl(0, 68%, 47%) !important;
}

.WOB .outline-secondary {
  border-color: hsl(0, 0%, 59%) !important;
}

.WOB .outline-secondary:hover {
  background-color: hsl(0, 0%, 51%) !important;
  border-color: hsl(0, 0%, 51%) !important;
}
/* --------------------- WOB END --------------------- */

/* --------------------- ABS BEGIN --------------------- */
.ABS #navbar {
  background-color: hsl(211, 32%, 25%);
}

/* .ABS .splash_bg {
  background-color: teal;
} */

.ABS .splash_session_bg {
  background-color: hsl(211, 32%, 25%);
  border: solid 1px hsla(210, 32%, 15%, 0.9);
}

/* .ABS .flyer_empty {
  background-color: #f5f5f5 !important;
} */

.ABS table.flyer_table tr {
  border-bottom: 1px solid hsl(211, 32%, 25%);
}

.ABS table.flyer_table tr:nth-child(even) {
  background-color: hsl(0, 0%, 100%);
}

.ABS table.flyer_table tr:nth-child(odd) {
  background-color: hsl(0, 0%, 91%);
}

.ABS table.flyer_table tr.table_header td,
.ABS table.flyer_table tr.table_footer td {
  background-color: hsl(211, 32%, 55%);
}

.ABS .flyer_form {
  background-color: hsl(211, 32%, 25%);
}

.ABS .flyer_form_content {
  background-color: hsl(198, 26%, 85%);
}

/* .ABS .entry_empty {
  background-color: #f5f5f5 !important;
} */

.ABS .entry_list {
  background-color: #fff;
}

.ABS .entry_list .entry_marked {
  background-color: hsl(211, 32%, 55%);
  border: solid 1px hsla(211, 32%, 45%, 0.8);
}

.ABS .entry_list .entry_unmarked {
  background-color: hsl(211, 32%, 75%);
  border: solid 1px hsla(211, 32%, 65%, 0.8);
}

.ABS .entry_list .entry_unmarked .divider {
  border-bottom: solid 1px hsla(211, 32%, 65%, 1) !important;
}

.ABS .entry_list .entry_marked .divider {
  border-bottom: solid 1px hsla(211, 32%, 45%, 1) !important;
}

.ABS .entry_form {
  background-color: hsl(211, 32%, 25%) !important;
}

.ABS .entry_form_content {
  background-color: hsl(198, 26%, 85%) !important;
  border-top: solid 1px hsl(198, 26%, 55%);
}

.ABS .entry_form_content .box {
  background-color: hsl(211, 32%, 70%);
  border: solid 1px hsla(211, 32%, 60%, 0.9);
}

.ABS .settings_bg {
  background-color: #f5f5f5;
}

.ABS .primary {
  background-color: hsl(191, 55%, 41%) !important;
  border-color: hsl(191, 55%, 41%) !important;
  color: #ffffff !important;
}

.ABS .primary:hover {
  background-color: hsl(191, 55%, 31%) !important;
  border-color: hsl(191, 55%, 31%) !important;
}

.ABS .secondary {
  background-color: hsl(178, 19%, 60%) !important;
  border-color: hsl(178, 19%, 60%) !important;
}

.ABS .secondary:hover {
  background-color: hsl(178, 19%, 50%) !important;
  border-color: hsl(178, 19%, 50%) !important;
}

.ABS .danger {
  background-color: hsl(0, 68%, 55%) !important;
  border-color: hsl(0, 68%, 55%) !important;
}

.ABS .danger:hover {
  background-color: hsl(0, 68%, 47%) !important;
  border-color: hsl(0, 68%, 47%) !important;
}

.ABS .outline-secondary {
  border-color: hsl(178, 19%, 60%) !important;
}

.ABS .outline-secondary:hover {
  background-color: hsl(178, 19%, 51%) !important;
  border-color: hsl(178, 19%, 51%) !important;
}
/* --------------------- ABS END --------------------- */
</style>
