import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

// Get a Firestore instance
export const db = firebase
  .initializeApp({
    apiKey: 'AIzaSyCgyUtP0REvRl1eBM7bN_zmiXPGQpB_qsU',
    authDomain: 'eizzo-6d285.firebaseapp.com',
    projectId: 'eizzo-6d285',
    storageBucket: 'eizzo-6d285.appspot.com',
    messagingSenderId: '998352608526',
    appId: '1:998352608526:web:aff8126d883c37fe17cb5f',
    measurementId: 'G-EVQNZ3N4ZT'

    // apiKey: 'AIzaSyAb5luJwdqm_PxX8G3lBYHjCDmWnZK3k0k',
    // authDomain: 'flyculator.firebaseapp.com',
    // databaseURL: 'https://flyculator.firebaseio.com',
    // projectId: 'flyculator',
    // storageBucket: 'flyculator.appspot.com',
    // messagingSenderId: '688486573336',
    // appId: '1:688486573336:web:58762a590092d8ffa5aa6d',
    // measurementId: 'G-ZW85F1L7CJ'
  })
  .firestore()

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }

// if using Firebase JS SDK < 5.8.0
db.settings({ timestampsInSnapshots: true })
